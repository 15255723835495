.socialLinks {
    color: #ddd;
    position: absolute;
    top: 3%;
    right: 6%;
    font-size: 22px;

    a, span {
        color: #ddd;
        text-decoration: none;
        margin-right: 10px;
        cursor: pointer;
    }

    a:hover, span:hover {
        color: #bebebe;
    }
}

.mintBtn {
    --bs-btn-color: #fff;
    --bs-btn-bg: #be3333;
    --bs-btn-border-color: #be3333;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #af2b2b;
    --bs-btn-hover-border-color: #af2b2b;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #9a1f1f;
    --bs-btn-active-border-color: #9e3030;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #c94e4e;
    --bs-btn-disabled-border-color: #9a3f3f;

    // .btn-lg
    --bs-btn-padding-y: 0.75rem;
    --bs-btn-padding-x: 1.5rem;
    --bs-btn-font-size: 1.75rem;
    --bs-btn-border-radius: 0.75rem;
}

.mintInfo {
    position: relative;
}

.mintInfoMintDiv {
    bottom: 0;
    position: absolute;
}

.mainContent {
    padding-top: 300px;
}

@media (max-width: 992px) {
    .mintInfo {
        margin-top: 2rem;
    }

    .mintInfoMintDiv {
        position: inherit;
        margin-top: 50px;
    }

    .mainContent {
        padding-top: 25%;
    }
}

.mintInfoTitle {
    font-size: 60px;
}

.mintInfoDescr {
    color: #aaa;
    margin-bottom: 15px;
    margin-top: 10px;
}

.mintInfoPrice {
    font-size: 30px;
    margin-bottom: 0;
}

.mintInfoMinted {
    font-size: 25px;
    margin-bottom: 0;
}



